import React from "react";
import { Flex, Box } from "@chakra-ui/react";
interface HeaderProps {
  image?: string;
}

export const Header: React.FC<HeaderProps> = ({ children, image = "" }) => {
  return (
    <Flex
      align="center"
      flexDir={["column", "column", "column", "row"]}
      textAlign={["center", "center", "center", "left"]}
      p={4}>
      {children}
      <Box>
        <svg
          className="hero-image"
          viewBox="0 0 486 484"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0)">
            <path
              d="M320.459 128.097H240.717C238.972 128.102 237.248 127.709 235.677 126.948C234.106 126.187 232.729 125.077 231.652 123.704C230.555 122.317 229.788 120.699 229.411 118.971C229.034 117.244 229.056 115.453 229.475 113.736C236.824 82.9527 236.824 50.8719 229.475 20.0888C229.056 18.3713 229.034 16.5808 229.411 14.8536C229.788 13.1264 230.555 11.508 231.651 10.1215C232.729 8.74796 234.106 7.63827 235.677 6.87692C237.248 6.11557 238.972 5.72262 240.717 5.72799H320.459C323.212 5.7359 325.873 6.72083 327.968 8.50735C330.063 10.2939 331.455 12.766 331.898 15.4834C337.146 49.5687 337.146 84.2565 331.898 118.342C331.455 121.059 330.063 123.531 327.968 125.318C325.873 127.104 323.212 128.089 320.459 128.097Z"
              fill="#E6E6E6"
            />
            <path
              d="M240.717 11.728C239.879 11.7252 239.051 11.9136 238.296 12.279C237.542 12.6443 236.881 13.177 236.363 13.8364C235.83 14.512 235.458 15.3003 235.275 16.1414C235.092 16.9824 235.104 17.854 235.309 18.6899C242.878 50.3928 242.878 83.4323 235.31 115.135C235.104 115.971 235.093 116.842 235.275 117.683C235.458 118.524 235.829 119.312 236.363 119.988L236.363 119.988C236.881 120.648 237.542 121.181 238.296 121.546C239.051 121.911 239.879 122.1 240.717 122.097H320.459C321.781 122.098 323.06 121.629 324.069 120.774C325.078 119.919 325.751 118.734 325.968 117.43C331.122 83.949 331.122 49.8761 325.968 16.395C325.751 15.0907 325.078 13.9057 324.069 13.0511C323.06 12.1964 321.781 11.7275 320.459 11.728L240.717 11.728Z"
              fill="white"
            />
            <path
              d="M303.057 40.5787H261.212C259.622 40.577 258.097 39.9443 256.972 38.8195C255.847 37.6947 255.214 36.1697 255.213 34.5789V32.2343C255.214 30.6436 255.847 29.1186 256.972 27.9938C258.097 26.869 259.622 26.2363 261.212 26.2345H303.057C304.648 26.2363 306.173 26.869 307.298 27.9938C308.423 29.1186 309.055 30.6436 309.057 32.2343V34.579C309.055 36.1697 308.423 37.6947 307.298 38.8195C306.173 39.9443 304.648 40.577 303.057 40.5787Z"
              fill="#E6E6E6"
            />
            <path
              d="M303.057 70.2045H261.212C259.622 70.2028 258.097 69.5701 256.972 68.4453C255.847 67.3205 255.214 65.7954 255.213 64.2047V61.8601C255.214 60.2694 255.847 58.7443 256.972 57.6195C258.097 56.4947 259.622 55.8621 261.212 55.8604H303.057C304.648 55.8621 306.173 56.4947 307.298 57.6195C308.423 58.7443 309.055 60.2694 309.057 61.8601V64.2047C309.055 65.7954 308.423 67.3205 307.298 68.4453C306.173 69.5701 304.648 70.2028 303.057 70.2045Z"
              fill="#E6E6E6"
            />
            <path
              d="M303.057 99.8303H261.212C259.622 99.8286 258.097 99.1959 256.972 98.0711C255.847 96.9463 255.214 95.4212 255.213 93.8305V91.4859C255.214 89.8952 255.847 88.3701 256.972 87.2453C258.097 86.1205 259.622 85.4879 261.212 85.4861H303.057C304.648 85.4879 306.173 86.1205 307.298 87.2453C308.423 88.3701 309.055 89.8952 309.057 91.4859V93.8305C309.055 95.4212 308.423 96.9463 307.298 98.0711C306.173 99.1959 304.648 99.8286 303.057 99.8303Z"
              fill="#E6E6E6"
            />
            <path
              d="M470.459 204.097H390.717C388.972 204.102 387.248 203.709 385.677 202.948C384.106 202.187 382.729 201.077 381.652 199.704C380.555 198.317 379.788 196.699 379.411 194.971C379.034 193.244 379.056 191.453 379.475 189.736C386.824 158.953 386.824 126.872 379.475 96.0888C379.056 94.3713 379.034 92.5808 379.411 90.8536C379.788 89.1264 380.555 87.508 381.651 86.1215C382.729 84.748 384.106 83.6383 385.677 82.8769C387.248 82.1156 388.972 81.7226 390.717 81.728H470.459C473.212 81.7359 475.873 82.7208 477.968 84.5073C480.063 86.2939 481.455 88.766 481.898 91.4834C487.146 125.569 487.146 160.256 481.898 194.342C481.455 197.059 480.063 199.531 477.968 201.318C475.873 203.104 473.212 204.089 470.459 204.097Z"
              fill="#E6E6E6"
            />
            <path
              d="M390.717 87.728C389.879 87.7252 389.051 87.9136 388.296 88.279C387.542 88.6443 386.881 89.177 386.363 89.8364C385.83 90.512 385.458 91.3003 385.275 92.1414C385.092 92.9824 385.104 93.854 385.309 94.6899C392.878 126.393 392.878 159.432 385.31 191.135C385.104 191.971 385.093 192.842 385.275 193.683C385.458 194.524 385.829 195.312 386.363 195.988L386.363 195.988C386.881 196.648 387.542 197.181 388.296 197.546C389.051 197.911 389.879 198.1 390.717 198.097H470.459C471.781 198.098 473.06 197.629 474.069 196.774C475.078 195.919 475.751 194.734 475.968 193.43C481.122 159.949 481.122 125.876 475.968 92.395C475.751 91.0907 475.078 89.9057 474.069 89.0511C473.06 88.1964 471.781 87.7275 470.459 87.728L390.717 87.728Z"
              fill="white"
            />
            <path
              d="M311.459 290.597H231.717C229.896 290.603 228.097 290.193 226.458 289.398C224.819 288.604 223.383 287.446 222.259 286.013C221.115 284.567 220.316 282.88 219.922 281.079C219.529 279.277 219.552 277.41 219.989 275.619C227.32 244.913 227.32 212.912 219.988 182.206C219.552 180.415 219.529 178.547 219.922 176.746C220.316 174.945 221.115 173.258 222.259 171.812C223.383 170.379 224.819 169.221 226.458 168.427C228.097 167.633 229.896 167.223 231.717 167.228H311.459C314.331 167.237 317.107 168.264 319.293 170.129C321.478 171.993 322.931 174.572 323.392 177.407C328.648 211.543 328.648 246.282 323.392 280.418C322.931 283.253 321.478 285.832 319.293 287.697C317.107 289.561 314.331 290.589 311.459 290.597Z"
              fill="#F2F2F2"
            />
            <path
              d="M453.057 131.579H411.212C409.622 131.577 408.097 130.944 406.972 129.82C405.847 128.695 405.214 127.17 405.213 125.579V123.234C405.214 121.644 405.847 120.119 406.972 118.994C408.097 117.869 409.622 117.236 411.212 117.235H453.057C454.648 117.236 456.173 117.869 457.298 118.994C458.423 120.119 459.055 121.644 459.057 123.234V125.579C459.055 127.17 458.423 128.695 457.298 129.82C456.173 130.944 454.648 131.577 453.057 131.579Z"
              fill="#E6E6E6"
            />
            <path
              d="M453.057 161.204H411.212C409.622 161.203 408.097 160.57 406.972 159.445C405.847 158.32 405.214 156.795 405.213 155.205V152.86C405.214 151.269 405.847 149.744 406.972 148.62C408.097 147.495 409.622 146.862 411.212 146.86H453.057C454.648 146.862 456.173 147.495 457.298 148.62C458.423 149.744 459.055 151.269 459.057 152.86V155.205C459.055 156.795 458.423 158.32 457.298 159.445C456.173 160.57 454.648 161.203 453.057 161.204Z"
              fill="#E6E6E6"
            />
            <path
              d="M271.811 251.5C284.514 251.5 294.811 241.203 294.811 228.5C294.811 215.797 284.514 205.5 271.811 205.5C259.109 205.5 248.811 215.797 248.811 228.5C248.811 241.203 259.109 251.5 271.811 251.5Z"
              fill="white"
            />
            <path
              d="M282.811 225.5H274.811V217.5C274.811 216.704 274.495 215.941 273.932 215.379C273.37 214.816 272.607 214.5 271.811 214.5C271.015 214.5 270.252 214.816 269.69 215.379C269.127 215.941 268.811 216.704 268.811 217.5V225.5H260.811C260.015 225.5 259.252 225.816 258.69 226.379C258.127 226.941 257.811 227.704 257.811 228.5C257.811 229.296 258.127 230.059 258.69 230.621C259.252 231.184 260.015 231.5 260.811 231.5H268.811V239.5C268.811 240.296 269.127 241.059 269.69 241.621C270.252 242.184 271.015 242.5 271.811 242.5C272.607 242.5 273.37 242.184 273.932 241.621C274.495 241.059 274.811 240.296 274.811 239.5V231.5H282.811C283.607 231.5 284.37 231.184 284.932 230.621C285.495 230.059 285.811 229.296 285.811 228.5C285.811 227.704 285.495 226.941 284.932 226.379C284.37 225.816 283.607 225.5 282.811 225.5Z"
              fill="#E6E6E6"
            />
            <path
              d="M300.811 17H258.811C257.618 16.9987 256.474 16.5241 255.631 15.6805C254.787 14.8369 254.312 13.6931 254.311 12.5V4.5C254.312 3.30694 254.787 2.16313 255.631 1.3195C256.474 0.475881 257.618 0.00134456 258.811 0H300.811C302.004 0.00134456 303.148 0.475881 303.992 1.3195C304.835 2.16313 305.31 3.30694 305.311 4.5V12.5C305.31 13.6931 304.835 14.8369 303.992 15.6805C303.148 16.5241 302.004 16.9987 300.811 17Z"
              fill="#CCCCCC"
            />
            <path
              d="M452.811 94H410.811C409.618 93.9987 408.474 93.5241 407.631 92.6805C406.787 91.8369 406.312 90.6931 406.311 89.5V81.5C406.312 80.3069 406.787 79.1631 407.631 78.3195C408.474 77.4759 409.618 77.0013 410.811 77H452.811C454.004 77.0013 455.148 77.4759 455.992 78.3195C456.835 79.1631 457.31 80.3069 457.311 81.5V89.5C457.31 90.6931 456.835 91.8369 455.992 92.6805C455.148 93.5241 454.004 93.9987 452.811 94Z"
              fill="#CCCCCC"
            />
            <path
              d="M88.596 471.061H100.856L104.689 423.773H88.594L88.596 471.061Z"
              fill="#FFB8B8"
            />
            <path
              d="M85.4692 467.058L109.613 467.057H109.614C111.635 467.057 113.635 467.455 115.502 468.229C117.369 469.002 119.065 470.135 120.494 471.564C121.923 472.993 123.056 474.689 123.829 476.556C124.603 478.422 125.001 480.423 125 482.444V482.944L85.47 482.945L85.4692 467.058Z"
              fill="#2F2E41"
            />
            <path
              d="M22.596 471.061H34.856L40.689 423.773H22.594L22.596 471.061Z"
              fill="#FFB8B8"
            />
            <path
              d="M19.4692 467.058L43.613 467.057H43.614C45.6346 467.057 47.6354 467.455 49.5022 468.229C51.3689 469.002 53.0652 470.135 54.4939 471.564C55.9227 472.993 57.056 474.689 57.8293 476.556C58.6025 478.422 59.0005 480.423 59.0005 482.444V482.944L19.47 482.945L19.4692 467.058Z"
              fill="#2F2E41"
            />
            <path
              d="M24.7712 456.123C23.5206 456.122 22.3159 455.652 21.3962 454.804C20.9026 454.354 20.505 453.808 20.2274 453.201C19.9497 452.593 19.7977 451.935 19.7805 451.267L14.8639 275.054L84.675 292.506L106.215 357.124C108.992 365.436 110.195 374.194 109.76 382.948L107.085 445.584C107.024 446.868 106.471 448.078 105.541 448.964C104.611 449.85 103.376 450.344 102.091 450.343H90.382C89.1735 450.338 88.0072 449.898 87.0971 449.103C86.187 448.307 85.5941 447.211 85.4272 446.014L77.0583 376.872C76.327 371.478 74.4401 366.306 71.5266 361.708L55.0571 335.632C54.947 335.458 54.7857 335.322 54.5954 335.243C54.4051 335.164 54.195 335.145 53.9939 335.19C53.7928 335.235 53.6105 335.341 53.4719 335.494C53.3332 335.646 53.2451 335.838 53.2195 336.042L40.2949 451.13C40.1561 452.298 39.61 453.379 38.7526 454.183C37.8953 454.987 36.7817 455.463 35.6079 455.527L25.0559 456.114C24.9609 456.119 24.8662 456.123 24.7712 456.123Z"
              fill="#2F2E41"
            />
            <path
              d="M73.0577 160.967C86.6224 160.967 97.6187 149.971 97.6187 136.406C97.6187 122.841 86.6224 111.845 73.0577 111.845C59.493 111.845 48.4966 122.841 48.4966 136.406C48.4966 149.971 59.493 160.967 73.0577 160.967Z"
              fill="#FFB8B8"
            />
            <path
              d="M84.3406 299.672C83.9179 299.672 83.497 299.618 83.0876 299.513H83.0869L13.8226 281.753C12.5842 281.436 11.5144 280.657 10.8331 279.575C10.1518 278.493 9.91083 277.192 10.1597 275.938L26.0669 190.247C27.0579 185.294 29.2363 180.655 32.4151 176.729C35.5938 172.803 39.6779 169.707 44.3166 167.708C48.8365 165.707 53.7793 164.85 58.7088 165.212C63.6383 165.574 68.403 167.144 72.5822 169.783C73.552 170.388 74.5288 171.047 75.4848 171.744C79.3337 174.583 82.4725 178.275 84.6549 182.531C86.8373 186.787 88.0041 191.491 88.0637 196.273L89.3286 294.602C89.3371 295.263 89.2145 295.919 88.9678 296.533C88.7212 297.147 88.3554 297.705 87.8916 298.177C87.4278 298.648 86.8752 299.023 86.2657 299.28C85.6562 299.537 85.0019 299.67 84.3406 299.672Z"
              fill="#7209B7"
            />
            <path
              d="M20.9493 300.68C20.1231 299.464 19.5735 298.082 19.3392 296.631C19.105 295.179 19.1918 293.694 19.5936 292.28C19.9954 290.866 20.7023 289.557 21.6646 288.446C22.6269 287.334 23.8212 286.448 25.1633 285.848L22.0825 250.246L38.4086 259.094L38.8312 291.546C39.6895 293.867 39.6664 296.423 38.7663 298.729C37.8661 301.034 36.1515 302.93 33.9474 304.056C31.7432 305.181 29.2026 305.46 26.807 304.838C24.4114 304.215 22.3272 302.736 20.9494 300.68H20.9493Z"
              fill="#FFB8B8"
            />
            <path
              d="M26.782 281.139C26.3213 281.139 25.8625 281.081 25.4163 280.966C24.4564 280.721 23.5805 280.222 22.8813 279.52C22.1822 278.818 21.6857 277.941 21.4443 276.98L13.4211 245.096C10.6172 233.96 11.9539 222.185 17.1824 211.961L33.9912 179.08C35.0538 175.143 37.6211 171.781 41.1395 169.72C44.658 167.659 48.8456 167.063 52.7993 168.061C54.7699 168.559 56.6222 169.443 58.2486 170.661C59.8751 171.88 61.2434 173.41 62.2742 175.162C63.3262 176.938 64.0142 178.907 64.2982 180.952C64.5821 182.997 64.4564 185.079 63.9282 187.075L40.0007 240.581L40.2849 272.616C40.2923 273.746 39.9525 274.852 39.3114 275.782C38.6703 276.713 37.759 277.425 36.7004 277.821L28.6931 280.794C28.0817 281.022 27.4345 281.139 26.782 281.139Z"
              fill="#7209B7"
            />
            <path
              d="M141.318 287.585C139.857 287.418 138.451 286.933 137.198 286.163C135.945 285.394 134.876 284.36 134.067 283.133C133.257 281.906 132.726 280.516 132.512 279.062C132.297 277.607 132.404 276.124 132.825 274.715L103.831 253.826L121.188 247.224L146.06 268.073C148.379 268.937 150.301 270.622 151.461 272.808C152.622 274.994 152.94 277.53 152.355 279.935C151.771 282.34 150.324 284.447 148.29 285.857C146.256 287.267 143.775 287.882 141.318 287.585H141.318Z"
              fill="#FFB8B8"
            />
            <path
              d="M126.14 272.331C125.285 272.33 124.442 272.131 123.677 271.749L94.2781 257.032C84.0094 251.892 75.9567 243.198 71.6174 232.566L57.6602 198.376C55.3697 195.002 54.4968 190.863 55.2296 186.852C55.9625 182.84 58.2424 179.278 61.5779 176.932C63.2444 175.76 65.1275 174.931 67.1174 174.495C69.1073 174.058 71.1642 174.022 73.1682 174.388C75.1958 174.753 77.1309 175.516 78.8613 176.634C80.5917 177.752 82.0831 179.202 83.249 180.9L108.194 233.94L132.66 254.623C133.521 255.354 134.138 256.333 134.425 257.426C134.712 258.519 134.657 259.674 134.267 260.734L131.297 268.743C130.909 269.793 130.209 270.699 129.29 271.339C128.366 271.984 127.267 272.331 126.14 272.331Z"
              fill="#7209B7"
            />
            <path
              d="M67.9002 161.343C69.2052 161.914 71.8775 151.516 70.6804 149.436C68.9002 146.343 69.0054 146.365 67.8236 144.319C66.6418 142.273 66.3774 139.472 67.9078 137.671C69.4382 135.871 72.9798 136.11 73.6782 138.367C73.2289 134.079 77.4701 130.633 81.6712 129.664C85.8723 128.696 90.3037 129.297 94.5279 128.435C99.4303 127.435 104.531 123.325 102.575 117.934C102.239 117.037 101.737 116.211 101.094 115.5C98.8341 112.959 95.6729 111.874 92.582 110.825C86.152 108.643 79.546 106.433 72.7599 106.674C67.1685 106.966 61.7836 108.882 57.2642 112.187C52.7448 115.492 49.2868 120.043 47.3136 125.283C46.8459 126.594 46.4836 127.94 46.2301 129.308C43.9009 141.833 51.1738 154.186 62.9864 158.955L67.9002 161.343Z"
              fill="#2F2E41"
            />
            <path
              opacity="0.2"
              d="M38.9 273.343L39.457 240.414L56.9 205.343L42.9 241.343L38.9 273.343Z"
              fill="black"
            />
            <path
              d="M197.077 355.982L123.439 325.387C121.825 324.722 120.384 323.698 119.225 322.392C118.066 321.086 117.221 319.533 116.753 317.851C116.272 316.15 116.185 314.361 116.499 312.622C116.814 310.882 117.521 309.237 118.567 307.811C137.165 282.204 149.474 252.578 154.498 221.331C154.77 219.585 155.437 217.923 156.448 216.473C157.459 215.022 158.787 213.822 160.332 212.962C161.854 212.107 163.551 211.611 165.294 211.51C167.037 211.41 168.78 211.709 170.39 212.383L244.028 242.979C246.568 244.043 248.647 245.973 249.896 248.427C251.145 250.88 251.483 253.697 250.849 256.377C242.617 289.867 229.308 321.9 211.384 351.363C209.933 353.702 207.698 355.451 205.078 356.297C202.458 357.143 199.623 357.031 197.077 355.982Z"
              fill="#E6E6E6"
            />
            <path
              d="M168.088 217.924C167.315 217.6 166.478 217.456 165.641 217.504C164.804 217.552 163.989 217.79 163.258 218.2C162.506 218.62 161.86 219.205 161.369 219.912C160.877 220.618 160.554 221.428 160.422 222.278C155.248 254.459 142.572 284.97 123.418 311.342C122.908 312.035 122.563 312.835 122.409 313.682C122.255 314.528 122.296 315.399 122.529 316.227L122.529 316.228C122.754 317.036 123.16 317.781 123.717 318.408C124.273 319.035 124.966 319.527 125.741 319.846L199.379 350.442C200.6 350.949 201.962 351.007 203.221 350.605C204.481 350.203 205.556 349.367 206.257 348.245C223.864 319.305 236.937 287.84 245.023 254.943C245.323 253.655 245.156 252.303 244.553 251.127C243.949 249.95 242.947 249.027 241.726 248.52L168.088 217.924Z"
              fill="white"
            />
            <path
              d="M207.297 286.031L168.655 269.976C167.186 269.364 166.021 268.195 165.414 266.725C164.807 265.254 164.807 263.603 165.416 262.134L166.316 259.968C166.928 258.5 168.097 257.335 169.567 256.727C171.038 256.12 172.689 256.121 174.158 256.73L212.801 272.785C214.269 273.397 215.435 274.566 216.042 276.037C216.649 277.507 216.648 279.158 216.039 280.628L215.14 282.793C214.528 284.261 213.358 285.427 211.888 286.034C210.418 286.641 208.767 286.64 207.297 286.031Z"
              fill="#7209B7"
            />
            <path
              d="M195.93 313.39L157.288 297.335C155.819 296.723 154.654 295.553 154.047 294.083C153.44 292.613 153.441 290.962 154.049 289.492L154.949 287.327C155.561 285.859 156.73 284.693 158.2 284.086C159.671 283.479 161.322 283.479 162.791 284.088L201.434 300.144C202.902 300.755 204.068 301.925 204.675 303.395C205.282 304.865 205.281 306.516 204.672 307.986L203.773 310.151C203.161 311.62 201.992 312.785 200.521 313.392C199.051 313.999 197.4 313.999 195.93 313.39Z"
              fill="#7209B7"
            />
            <path
              d="M222.781 247.582C222.203 247.582 221.63 247.469 221.094 247.251L182.156 231.509C181.05 231.06 180.168 230.192 179.702 229.093C179.236 227.995 179.225 226.757 179.671 225.65L182.669 218.233C183.117 217.127 183.985 216.244 185.084 215.778C186.183 215.312 187.422 215.301 188.528 215.749L227.466 231.491C228.572 231.94 229.454 232.808 229.92 233.906C230.386 235.005 230.397 236.243 229.951 237.35L226.953 244.767C226.616 245.597 226.039 246.309 225.296 246.81C224.553 247.312 223.678 247.58 222.781 247.582Z"
              fill="#CCCCCC"
            />
            <path
              d="M141 483.5H1C0.734784 483.5 0.480444 483.395 0.292908 483.207C0.105371 483.02 0 482.765 0 482.5C0 482.235 0.105371 481.98 0.292908 481.793C0.480444 481.605 0.734784 481.5 1 481.5H141C141.265 481.5 141.52 481.605 141.707 481.793C141.895 481.98 142 482.235 142 482.5C142 482.765 141.895 483.02 141.707 483.207C141.52 483.395 141.265 483.5 141 483.5Z"
              fill="#CCCCCC"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="485.834" height="483.5" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </Box>
    </Flex>
  );
};
